<template>
  <div class="row">
    <div class="flex xs12">
      <table
        class="va-table va-table--striped va-table--hoverable table-card"
        style="width: 100%;"
      >
        <tbody>
          <text-row
            v-if="report.team"
            :title="$t('tables.headings.team')"
            size="50"
          >
            {{ report.team.code }}
          </text-row>
        </tbody>
      </table>
    </div>
    <div class="flex xs12">
      {{ report.testimony_es }}
      <div v-show="report.testimony_en">
        <br>
        <hr>
        <p>
          <strong>{{$t('layout.translation')}}:</strong>
        </p>
        <br>
        {{ report.testimony_en }}
      </div>
    </div>
  </div>
</template>

<script>
const TextRow = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextRow')

export default {
  name: 'monthly-testimony',
  components: {
    TextRow,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
}
</script>
